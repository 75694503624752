
import { signInWithCode, signInWithIdAndPassword } from "@/api/auth";
import { saveErrorLog } from "@/api/error";
import store from "@/store";
import liff from "@line/liff/dist/lib";
import { Options, Vue } from "vue-class-component";
import { QrcodeStream } from "vue3-qrcode-reader";

function isValidLoginId(loginId: string): boolean {
  return /^[0-9a-zA-Z]{6}-[0-9a-zA-Z]{3,}$/.test(loginId);
}

function isValidPassword(password: string): boolean {
  return /^[a-zA-Z0-9!-/:-@¥[-`{-~]{8,64}$/.test(password);
}

@Options({
  components: {
    QrcodeStream
  }
})
export default class AuthReader extends Vue {
  title = "スキャンするコードを見つけてください";

  get load(): boolean {
    return store.state.loading;
  }

  async onInit(promise: Promise<MediaTrackCapabilities>) {
    try {
      await promise;
    } catch (e) {
      if (e.name === "NotAllowedError") {
        this.title = "カメラへのアクセスが許可されていません";
      } else if (e.name === "NotFoundError") {
        this.title = "利用できるカメラが検出されませんでした";
      } else if (e.name === "NotReadableError") {
        this.title =
          "既にカメラが使用されているためカメラを使用することができません";
      } else {
        this.title =
          "このブラウザまたは端末ではQRコードリーダーは対応していません";
      }
    }
  }

  async onDecode(content: string) {
    if (content === "") {
      return;
    }
    const codes = content.split(" ");
    const isCode = content.length === 20 && codes.length === 1;
    if (!isCode && codes.length !== 3) {
      alert("QRコードが正しくありません");
      return;
    }

    let loginId = "";
    let password = "";
    let schoolId = "";
    if (!isCode) {
      loginId = codes[0];
      password = codes[1];
      schoolId = codes[2];
      if (!isValidLoginId(loginId) || !isValidPassword(password) || !schoolId) {
        alert("QRコードが正しくありません");
        return;
      }
    }

    store.commit("SET_LOADING", true);
    store.commit("SET_LOAD_TEXT", "ログイン処理中...");

    let token: string | undefined;
    if (store.state.isInLiff) {
      if (!liff.isLoggedIn()) {
        store.commit("SET_LOADING", false);
        store.commit("SET_LOAD_TEXT", "");
        alert("LINEのログインが完了していません。アプリを開き直してください。");
        return;
      }
      const accessToken = liff.getAccessToken();
      token = accessToken ? accessToken : undefined;
    }

    try {
      store.commit("SET_LOGIN_EXECUTED", true);
      if (isCode) {
        await signInWithCode(content, token);
      } else {
        await signInWithIdAndPassword(loginId, password, schoolId, token);
      }
    } catch (e) {
      if (e.code === "not-found" && e.code === "permission-denied") {
        alert(
          "このQRコードは現在使用できません。\n最新のログイン用QRコードを再度スクールの先生から共有してもらってください。"
        );
      } else {
        alert(`ログインに失敗しました\n\n${e}`);
        await saveErrorLog(
          store.state.student,
          e.code,
          e.message,
          "Failed to sign in"
        );
      }
      store.commit("SET_LOGIN_EXECUTED", false);
      return;
    }
    store.commit("SET_LOADING", false);
    store.commit("SET_LOAD_TEXT", "");
    const path = store.state.redirect;
    store.commit("SET_REDIRECT", "/");
    this.$router.replace(path);
  }
}
